import React from "react"

import Searchbar from "../components/searchbar"


const Category = () => (
    <>
        <Searchbar />
    </>
)

export default Category
