import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from "react-use-flexsearch"
import SEO from "./seo"
import { SectionDivider, useUtilStyles } from "./utils"
import { Box, Container, Typography } from "@material-ui/core"
import Listing from "./listing"
import { styled } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Helmet from "react-helmet"

const Searchbar = () => {


  // const { search } = queryString.parse(window.location.search)
  const [ query, setQuery ] = React.useState('')

  const data = useStaticQuery(graphql`
    query SearchbarQuery {
      localSearchPosts {
        index
        store
      }
    }
  `)

  const autoCompleteArray = []
  for (const [key, value] of Object.entries(data.localSearchPosts.store)) {
    autoCompleteArray.push(value.title)
  }
  const uniqueArray = [...new Set(autoCompleteArray)]

  const results = useFlexSearch(
    query,
    data.localSearchPosts.index,
    data.localSearchPosts.store
  )

  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#c260ba',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#c260ba',
      },
    },
  });

  return (
    <>
      <SEO />
      <Helmet>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@tarekraafat/autocomplete.js@10.2.7/dist/css/autoComplete.min.css" />
      </Helmet>
      <Container maxWidth="lg" className={useUtilStyles().topSpacer}>
        <section>
          <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
          <Typography variant="h3" component="h2" gutterBottom>
            Search
          </Typography>

            <Autocomplete
              options={uniqueArray}
              style={{ maxWidth: 600, width: '100%' }}
              freeSolo
              inputValue={query}
              onInputChange={(event, value) => {
                setQuery(value);
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  variant="outlined"
                  autoFocus
                />
              )}
            />

            {/* <TextField id="outlined-basic" variant="outlined"  /> */}
            {console.log(results)}
            <Listing posts={results} />
          </Box>
        </section>
      </Container>
    </>
  )
}

export default Searchbar